<template>
  <div class="my-6 flex items-center justify-between">
    <div>
      <h3 class="font-bold">
        Edit record
      </h3>

      <span class="text-sm text-gray-500"
        >Verify the following information and click the <b>save</b> button to confirm changes.
      </span>
    </div>
    <div class="flex justify-end">
      <Button @click="edit" color="primary" text="Save" />
    </div>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-3 gap-x-2 mb-6">
      <div class="flex-1 mr-3 mb-2">
        <Input
            v-model="data.userName"
            type="default"
            label="User"
            placeholder="Select an item"
            :readonly="true"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.projectName"
          type="default"
          label="Project"
          placeholder="Select an item"
          :readonly="true"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.activityName"
          type="default"
          label="Activity"
          placeholder="Select an item"
          :readonly="true"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.labelName"
          type="default"
          label="Label"
          placeholder="Select an item"
          :readonly="true"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
            v-model="data.categoryName"
            type="default"
            label="Category"
            placeholder="Select an item"
            :readonly="true"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
            v-model="data.disciplineName"
            type="default"
            label="Discipline"
            placeholder="Select an item"
            :readonly="true"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="data.description"
          type="default"
          label="Description"
          placeholder="Description"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="data.worked_hour"
          type="time"
          label="Time Spent"
          placeholder="Worked hours"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="data.worked_date"
          type="date"
          label="Worked Date"
          placeholder="Worked Date"
        />
      </div>
    </span>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PATCH } from "../../services/api";
import { toDate } from "../../services/formater";
import { isEmpty } from "../../services/validate";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "editProjects",
  components: {
    Input,
    Button,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal")
    const data = ref({});
    const institutions = ref([]);

    onMounted(async () => {
      loader.open();
      try {
        data.value = await GET(`hours_record/${route.params.id}`);
        data.value.userName = data.value.users_id.name;
        data.value.projectName = data.value.projects_id.name;
        data.value.activityName = data.value.activities_id.name;
        data.value.labelName = data.value.label_id.name;
        data.value.categoryName = data.value.categories_id.name;
        data.value.disciplineName = data.value.disciplines_id.name;
        data.value.worked_date = data.value.worked_date.split('T')[0];
        /*data.value = data.value[0].plan;
        data.value.status = data.value.deleted_at ? 'INATIVA' : 'ATIVA';
        institutions.value = await GET(`institutions`);
        institutions.value.map( institution => institutions.value.push({label: institution.name, value: institution.id}));
        data.value['institutions_id'] = institutions.value.find(institution =>  institution.value === data.value['institutions_id']).label;
        data.value['accept_negative'] = data.value['accept_negative'] == 1 ? 'sim' : 'nao';*/
        loader.close();
      } catch(e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    });

    const edit = async () => {
      modal.open("Attention", "Do you really wish to edit this project?", "warning", "Yes" , async () => {
        const obj = {worked_hour: data.value.worked_hour, worked_date: data.value.worked_date};
        console.log(obj);
        const validate = isEmpty(obj);
        if (validate) {
          validate && alert.open("Atenção!", validate, "warning");
        } else {
          try {
            loader.open();
            const copyObj = { ...obj, description: data.value.description };  
            const response = await PATCH(`hours_record/${route.params.id}`, copyObj);
            alert.open(
              "Success!",
              `Record was successfully edited!`,
              //<b>${response.name}</b>
              "success"
            );
            loader.close();
            router.push("/");
          } catch (e) {
            //data.value['institutions_id'] = institutions.value.find( institution => institution.value == data.value['institutions_id']).label;
            //data.value['accept_negative'] = data.value['accept_negative'] === '1' ? 'sim' : 'nao';
            loader.close();
            if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
              localStorage.removeItem("t_dynamik");
              localStorage.removeItem("info_dynamik");
              router.push({ path: '/login'});
            }
            alert.open("Error", e, "danger");
          }
        }
      },
      modal.close());
    };
      
    return { edit, data, toDate, institutions };
  },
};
</script>

<style>
</style>